@charset "UTF-8";
@import '../vars.scss';
/*--------------------------------
single product
--------------------------------*/
.single_product {
  padding-bottom: 18rem;
  @include mq() {
    padding-bottom: 4rem;
  }
  .breadcrumb {
    width: 72%;
    max-width: 140rem;
    margin: 3rem auto 0;
    @include mq('lg-md') {
      width: 90%;
    }
    @include mq() {
      display: none;
    }
    li {
      display: inline-block;
      margin: 0 3rem;
      position: relative;
    }
    li:not(:last-of-type)::after {
      @include arrow(#707070 #707070 transparent transparent);
      content: "";
      position: absolute;
      top: 50%;
      width: 8px;
      height: 8px;
      margin-left: 2.5rem;
      transform: rotate(45deg) translateY(-50%);
    }
  } //breadcrumb
  .page_ttl {
    margin-top: 4rem;
  }
  .product_detail {
    width: 94%;
    max-width: 130rem;
    margin: 10rem auto 0;
    @include flex;
    @include mq() {
      width: 100%;
      margin: 6rem auto 0;
      flex-direction: column;
    }
    .product_img {
      width: 58%;
      @include mq() {
        width: 100%;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .detail_cont {
      width: 38%;
      @include mq() {
        width: 68%;
        margin: 0 auto;
      }
    }
    .product_name {
      font-size: 2.8rem;
      @include mq() {
        font-size: calcvw(32);
        width: 68%;
        margin: 0 auto 3rem;
      }
    }
  } //product_detail
  .detail_cont {
    @include mq() {
      display: flex;
      flex-direction: column-reverse;
    }
    .upper_area {
      width: 90%;
      margin: 1rem auto;
      @include mq() {
        width: 100%;
        margin: 0 auto;
      }
    }
    .desc {
      margin-top: 4rem;
    }
    .box {
      border: 1px solid $color-base;
      margin-top: 5rem;
      padding: 4vw 3vw 3vw;
      @media screen and (max-width: 768px) and (min-width: 401px) {
        padding: 3rem 8vw;
      }
      @media screen and (max-width: 400px) and (min-width: 300px) {
        padding: 3rem 1rem;
      }
      @include mq() {
        margin-top: 3rem;
      }
      dt, dd {
        display: inline-block;
      }
      dt {
        width: 38%;
      }
      dd {
        width: 58%;
      }
    }
    .btn_contact {
      background: $color-base;
      color: #fff;
      display: block;
      font-size: 1vw;
      margin-top: 4rem;
      padding: .6em 1rem;
      width: 100%;
      text-align: center;
      @include mq('xxl-min') {
        font-size: 1.6rem;
      }
      @media screen and (max-width: 768px) and (min-width: 401px) {
        font-size: 1.4rem;
      }
      @media screen and (max-width: 400px) and (min-width: 300px) {
        font-size: 2.7vw;
      }
      @include mq() {
        margin-top: 2rem;
        padding: 1em 1rem;
      }
    }
  }
  .main_desc {
    background: #eee;
    margin: 10rem auto 0;
    padding: 7rem 5rem;
    width: 50%;
    max-width: 100rem;
    min-width: 70rem;
    @include mq() {
      margin: 6rem auto 0;
      padding: 3rem;
      width: 100%;
      min-width: inherit;
    }
    .desc_txt {
      @media screen and (max-width: 768px) and (min-width: 401px) {
        width: 72%;
      }
      @media screen and (max-width: 400px) and (min-width: 300px) {
        width: 100%;
      }
      @include mq() {
        margin: 0 auto;
      }
    }
  }
  .related_product {
    width: 94%;
    max-width: 100rem;
    margin: 16rem auto 0;
    @include mq() {
      width: 100%;
      margin: 3rem auto 0;
    }
    .ttl_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &::before,
      &::after {
        content:  "";
        flex-grow: 1;
        background: $color-base;
        height: 1px;
      }
      &::before {
        margin-right: 2em;
      }
      &::after {
        margin-left: 2em;
      }
    }
    .ttl {
      line-height: 1.2;
      text-align: center;
      .ja {
        font-size: 2.8rem;
        @include mq() {
          font-size: calcvw(32);
        }
      }
      .en {
        font-size: 1rem;
      }
    }
    .item_list {
      @include flex;
      margin-top: 7rem;
      @include mq() {
        flex-direction: column;
        margin-top: 3rem;
      }
      li {
        width: 30%;
        @include mq() {
          width: 40%;
          margin: 1rem auto 0;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  } //related_product
} //single_product

.archive_product {
  padding-bottom: 50rem;
  @include mq() {
    padding-bottom: 5rem;
  }
  .cat_list {
    font-size: 2rem;
    margin: 7rem auto;
    width: 56rem;
    @include flex;
    @include mq() {
      font-size: calcvw(24);
      margin: 4rem auto;
      width: 74%;
    }
    li {
      text-align: center;
    }
    li:after {
      content: "";
      display: block;
      background: #eee;
      height: 1px;
      width: 14rem;
      margin: 2px auto 0;
      background: #eee;
      @include mq() {
        width: 5em;
      }
    }
    li.active::after {
      background: $color-base;
    }
  } //cat_list
  .kv {
    position: relative;
    img {
      width: 100%;
    }
    .lead .note {
      font-size: 1.6rem;
    }
    .txt_box {
      background: #fff;
      font-size: 1.6rem;
      position: absolute;
      bottom: 0rem;
      padding: 2rem 10% 3rem 13%;
      width: 68%;
      @include mq('md-sm') {
        padding: 2rem 4% 3rem;
      }
      @include mq() {
        position: static;
        margin: 3rem auto;
        padding: 0;
        width: 74%;
      }
    }
    .product_ttl {
      font-size: 2.8rem;
      margin-bottom: 1rem;
      @include mq('lg-md') {
        font-size: calcvwpc(32);
      }
      @include mq() {
        font-size: calcvw(32);
        text-align: center;
      }
      .large {
        font-size: 4.4rem;
        @include mq('lg-md') {
          font-size: calcvwpc(48);
        }
        @include mq() {
          font-size: calcvw(44);
        }
      }
      &+p {
        @include mq('lg-md') {
          font-size: calcvwpc(18);
        }
      }
    } //product_ttl
    .archive_bamboo_kv {
      .left_txt {
        margin-top: 8em;
      }
      .slide_display_txt {
        top: 26%;
        right: 16%;
        @include mq() {
          top: 14%;
        }
      }
    } //archive_bamboo_kv
    .archive_tableware_kv {
      .left_txt {
        margin-top: 3em;
      }
      .slide_display_txt {
        top: 26%;
        right: 14%;
        @include mq() {
          top: 14%;
        }
      }
    } //archive_tableware_kv
  } //kv
  .cont_wrap {
    background: #fff;
    padding: 5rem 5rem 0;
    width: 68%;
    max-width: 130rem;
    margin: 0 auto;
    @include mq() {
      padding: 2rem 0 0;
      width: 100%;
    }
  }
  .cont_inner {
    max-width: 100rem;
    margin: 0 auto;
    @include mq() {
      width: 68%;
    }
  }
  .lead {
    position: relative;
    top: -12rem;
    margin-bottom: -12rem;
    @include mq() {
      top: 0;
      margin-bottom: 0;
    }
  }
  .product_list {
    width: 60%;
    max-width: 100rem;
    margin: 10rem auto;
    @include mq() {
      width: 86% ;
      margin: 0 auto;
    }
    >.note {
      margin-top: 3rem;
      background: #fff;
    }
    .count {
      margin-bottom: 1rem;
      text-align: right;

    }
    .list_wrap {
      @include flex($wrap: wrap);
      &::after {
        content: "";
        display: block;
        width: 30%;
      }
      li {
        width: 30%;
        margin-bottom: 6rem;
        position: relative;
        @include mq() {
          width: 48%;
          margin-bottom: 3rem;
        }
        img {
          width: 100%;
          height: auto;
        }
      }
    } //list_wrap
    &.no_margin {
      margin: 0 auto;
    }
    .sold_box {
      position: relative;
      .txt_sold {
        font-size: 2.4rem;
        position: absolute;
        top: -1em;
        right: 1rem;
        @include mq() {
          font-size: 2rem;
        }
      }
    }
  } //product_list
  .page_nav {
    margin-top: 4rem;
    text-align: center;
    @include mq() {
      margin-top: 2rem;
    }
    .prev {
      padding-right: 5em;
      @include mq() {
        padding-right: 2em;
      }
    }
    .next {
      padding-left: 5em;
      @include mq() {
        padding-left: 2em;
      }
    }
  }
  .page-numbers {
    margin: 0 1rem;
    @include mq() {
      margin: 0 6px;
    }
  }
  .page-numbers.current {
    background: #707070;
    border-radius: 3em;
    line-height: 1.6;
    width: 1.5em;
    height: 1.5em;
    display: inline-block;
    color: #fff;
    text-align: center;
  }
} //archive_product
