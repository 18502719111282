@charset "UTF-8";
@import 'vars.scss';
html, body,
h1, h2, h3, h4, h5, h6,
a, p, span,
em, small, strong,
sub, sup,
mark, del, ins, strike,
abbr, dfn,
blockquote, q, cite,
code, pre,
ol, ul, li, dl, dt, dd,
div, section, article,
main, aside, nav,
header, hgroup, footer,
img, figure, figcaption,
address, time,
audio, video,
canvas, iframe,
details, summary,
fieldset, form, label, legend,
table, caption,
tbody, tfoot, thead,
tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
}

* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: inherit;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
  font-size: 100%;
}

/* font-size 10px = 1rem */
html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%; /*iphone safari文字調整*/
}

body {
  color: $color-base;
  font-size: 1.4rem;
  font-family: $font-base;
  // font-feature-settings: "palt";
  line-height: 1.8;
  letter-spacing: .05em;
}

article, aside,
footer, header,
nav, section, main {
  display: block;
}

a,
a:link,
a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

nav, ul, ol, li {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

// テーブルスタイルのボーダーなし
/*
table, th, td {
  border: 0 none;
}
*/

img, video {
  max-width: 100%;
  height: auto;
}

img {
  border-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

button {
  cursor: pointer;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

/* フォームのリセット */
input, button, textarea, select, option {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  font-family: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

/* プレースホルダー */
@mixin placeholderColor($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color:$color;
  }
  &:-moz-placeholder {
    color:$color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color:$color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color:$color;
  }
  &:placeholder {
    color: $color;
  }
}
input, select, textarea {
  @include placeholderColor(#ccc);
}

/* デフォルトスタイルの時はコメントアウト */
input[type="radio"],
input[type="checkbox"] {
  display: none;
}

textarea {
  resize: none;
  font-family: $font-base;
}

/* クロームのオートフィル背景色 */
/*
input:-webkit-autofill {
  -webkit-transition: background-color 9999s;
  transition: background-color 9999s;
}
*/

strong {
  font-weight: normal;
}

em {
  font-style: normal;
}


dt {
  font-weight: normal;
}

fieldset {
  border: 0 none;
}

/* pc tel link off */
@media screen and (min-width: 769px) {
  a[href^="tel:"] {
    pointer-events: none;
  }
}

