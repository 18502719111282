@charset "UTF-8";
@import '../vars.scss';
header {
  overflow: hidden;
  @include mq() {
  }
  .nav_area {
    background: #fff;
    position: fixed;
    right: 0;
    height: 100vh;
    width: 6.4vw;
    max-width: 9rem;
    z-index: 9999;
    @include mq() {
      height: 5rem;
      width: 100%;
      max-width: inherit;
    }
    .nav_wrap {
      height: 100%;
      padding: 1vw;
      position: relative;
      @include mq() {
        @include flex;
        align-items: center;
        padding: 1vw 1.6rem;
        position: static;
        transform: none;
        transition: 3s all;
      }
      .logo {
        @include mq() {
          width: 18%;
          max-width: 8rem;
          img {
            vertical-align: middle;
          }
        }
      }
    }
    .menu_btn {
      position: absolute;
      top: 46%;
      left: 50%;
      transform: translateX(-50%);
      @include mq() {
        position: static;
      }
      .txt_menu {
        @include mq() {
          font-size: 1rem;
          line-height: 1.2;
        }
      }
    }
    #burger {
      display: block;
      width: 4rem;
      margin: 0 auto;
      @include mq() {
        width: 2rem;
      }
      span {
        background: $color-base;
        display: block;
        margin: 8px 0;
        width: 100%;
        height: 3px;
        transition: .3s all cubic-bezier(0.4,0.25,0.3,1);
        transform-origin: right;
        @include mq() {
          margin: 4px 0;
          height: 1px;
        }
      }
      .topline.del,
      .mdline.del,
      .underline.del {
        transform: scale(0);
      }
    } //burger
    #cross {
      width: 4rem;
      height: 4rem;
      position: absolute;
      top: 32%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      visibility: hidden;
      @include mq() {
        width: 2rem;
        height: 2rem;
      }
      span {
        background: $color-base;
        position: absolute;
        transition: .3s all cubic-bezier(0.4,0.25,0.3,1);
        &.right {
          width: 100%;
          height: 3px;
          left: 2%;
          top: 48%;
          @include mq() {
            height: 1px;
          }
        }
        &.left {
          height: 100%;
          width: 3px;
          top: 2%;
          left: 48%;
          @include mq() {
            width: 1px;
          }
        }
        &.right.del, &.left.del {
          transform: scale(0);
        }
      }
    } //cross
    .to_shop {
      @extend %abs_c_horizon;
      bottom: 1rem;
      @include mq() {
        position: static;
        transform: none;
        width: 7%;
        max-width: 3rem;
        img {
          vertical-align: middle;
        }
      }
    }
  } //nav_area
  .company_name_wrap {
    align-items: center;
    display: flex;
    justify-content: center;
    // backdrop-filter: blur(4px);
    // -webkit-backdrop-filter: blur(4px);
    height: 100%;
    width: 38%;
    @include mq() {
      display: none;
    }
    .company_name {
      opacity: 0;
      text-align: center;
      transition: 1s all;
      span {
        display: block;
      }
      .ja {
        font-size: calcvwpc(48);
      }
      .en {
        font-size: calcvwpc(18);
      }
    }
  } //company_name_wrap
} //header

.open_bg {
  // background: url(../assets/img/top/kv_top01.png) no-repeat;
  // background-size: cover;
  background: rgba(255,255,255,.3);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translate(100%, 0);
  transform-origin: right;
  z-index: 20;
  width: 100%;
  @include mq() {
    // background: url(../assets/img/top/kv_top01_sp.png) no-repeat;
    // background-size: cover;
    // background-position: center;
    margin: 5rem 0;
    width: 100%;
  }
}

.gmenu_wrap {
  background: #fff;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translate(100%, 0);
  transform-origin: right;
  padding: 0 calcvwpc(150);
  z-index: 100;
  margin-right: 6.4vw;
  width: calc(62% - 6.4vw);
  @include mq('xxl-min') {
    width: calc(62% - 9rem);
    margin-right: 9rem;
  }
  @include mq() {
    background: rgba(255,255,255,.3);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    margin-right: 0;
    width: 100%;
  }
  .txt_wrap {
    height: 100%;
    width: 100%;
    align-items: center;
    @include flex;
    @include mq() {
      height: auto;
      flex-direction: column;
      margin-top: 16vw;
    }
  } //txt_wrap
  .company_name {
    @include mq() {
      text-align: center;
      .ja {
        font-size: calcvw(28);
      }
      .en {
        font-size: calcvw(22);
      }
    }
  } //company_name
} //gmenu_wrap

.gmenu_list {
  @include flex;
  width: 100%;
  @include mq() {
    display: block;
    margin-top: 5rem;
  }
  .link {
    text-align: center;
    span {
      display: block;
    }
    .en {
      line-height: 1.6;
      font-size: calcvwpc(32);
      @include mq() {
        font-size: calcvw(36);
        margin-top: 1rem;
      }
      &:after {
        content: "";
        display: block;
        width: 1.2em;
        height: 1px;
        background: $color-base;
        margin: 0 auto;
        transform: scale(0);
        transform-origin: center;
        transition: .2s all;
      }
    }
    .ja {
      font-size: calcvwpc(18);
      margin-top: .6em;
      @include mq() {
        font-size: calcvw(18);
        margin-top: 0;
      }
    }
    &:hover {
      .en {
        &:after {
          transform: scale(1);
        }
      }
    }
  } //link
} //gmenu_list

header.open {
  width: 100%;
  height: 100vh;
  padding-right: 6.4vw;
  position: fixed;
  z-index: 9999;
  @include mq('xxl-min') {
    padding-right: 9rem;
  }
  #cross {
    visibility: visible;
  }
  .company_name {
    opacity: 1;
  }
  .nav_wrap {
    @include mq() {
      box-shadow: 0 1rem 1rem #eee;
    }
  }
} //header.open



