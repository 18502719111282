@charset "UTF-8";
@import '../vars.scss';
/*--------------------------------
top kv
--------------------------------*/
.page_top {
  padding-bottom: 32rem;
  @include mq() {
    padding-bottom: 6rem;
  }
  .wrap {
    width: 90%;
    max-width: 140rem;
    margin: 0 auto;
    @include mq() {
      width: 80%;
    }
  }
  .sec_ttl {
    font-size: 3.2rem;
    line-height: 1.2;
    width: 10em;
    @include mq() {
      font-size: 2.4rem;
      width: 100%;
    }
    span {
      display: block;
      line-height: 1.4;
    }
    .ja {
      font-size: 1.4rem;
      @include mq() {
        font-size: 1.2rem;
      }
    }
    .line_fade {
      @include mq() {
        width: 50%;
      }
    }
  }
  .kv {
    position: relative;
    .swiper-slide-content {
      border-bottom: 1px solid;
      font-weight: bold;
      padding: 0 2em .4rem 0;
      text-indent: 3em;
      position: absolute;
      top: 45%;
      left: 0;
      transition: .6s .2s cubic-bezier(.79,.17,.15,.96);
      @include mq ('md-sm') {
        font-size: calcvwpc(22);
      }
      @include mq() {
        border-bottom: none;
        padding: 0 0 .4rem 0;
        text-indent: 0;
        top: 88%;
        left: 50%;
        transform: translateX(-50%);
        font-size: calcvw(18);
      }
      img {
        width: 100%;
      }
    } //swiper-slide-content
    .swiper-slide-content-2 {
      opacity: 0;
    }
    .swiper-slide-content::after {
      content: "";
      background: $color-base;
      width: 1px;
      height: 3rem;
      transform: rotate(-45deg);
      transform-origin: bottom;
      position: absolute;
      right: 0;
      bottom: 0;
      @include mq() {
        width: 70%;
        height: 1px;
        transform: rotate(0) translateX(50%);
        transform-origin: center;
        right: 50%;
      }
    }
    .swiper-pagination {
      left: 0;
      width: 8em;
      top: 50%;
      @media screen and (min-width: 1500px) {
        top: 49%;
      }
      @include mq ('lg-md') {
        top: 52%;
      }
      @include mq() {
        left: 50%;
        width: 8em;
        top: inherit;
        bottom: 1.4rem;
        transform: translateX(-50%);
      }
    }
    .swiper-pagination-bullet {
      height: 1.2rem;
      width: 1.2rem;
      @include mq() {
        width: calcvw(20);
        height: calcvw(20);
      }
    }
    .swiper-pagination-bullet-active {
      background: $color-base;
    }
    .logo {
      position: absolute;
      top: 3rem;
      left: 5rem;
      width: 9.4rem;
      z-index: 2;
      @include mq() {
        margin: 0 0 3rem;
        width: calcvw(140);
      }
    }
    .top_ttl_warp {
      // background: rgba(255,255,255,.4);
      // backdrop-filter: blur(10px);
      // -webkit-backdrop-filter: blur(10px);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      height: calc(100% - 8px);
      width: 28%;
      max-width: 50rem;
      z-index: 1;
      @include mq() {
        width: 80%;
        height: 22%;
        top: 40%;
        background: rgba(255,255,255,.4);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
      }
    }
    .top_ttl {
      @extend %abs_c;
      font-size: 1.8rem;
      text-align: center;
      width: 90%;
      z-index: 1;
      top: 47%;
      @include mq ('lg-md') {
        font-size: calcvwpc(22);
      }
      @include mq() {
        font-size: calcvw(32);
      }
      .company_name {
        font-size: 3.2rem;
        font-weight: bold;
        display: inline-block;
        margin-top: 7rem;
        white-space: nowrap;
        @include mq ('lg-md') {
          font-size: calcvwpc(32);
        }
        @include mq() {
          font-size: calcvw(24);
          margin-top: 1.6rem;
        }
      }
    } //top_ttl
    .scroll_txt {
      display: none;
      @include mq() {
        display: inline-block;
        position: absolute;
        bottom: 6rem;
        left: 78%;
        z-index: 10;
      }
    }
    .scroll_line {
      @extend %abs_c_horizon;
      background: #000;
      display: block;
      width: 1px;
      height: 0;
      top: 94%;
      z-index: 10;
      transition: 1s .6s cubic-bezier(.79,.17,.15,.96);
      @media screen and (max-width: 768px) and (min-width: 401px) {
        left: 81%;
      }
      @media screen and (max-width: 400px) and (min-width: 300px) {
        left: 83%;
      }
      @include mq() {
        top: inherit;
        bottom: .5%;
      }
    }
    .scroll_line.show {
      height: 15rem;
      transition: 1s .6s cubic-bezier(.79,.17,.15,.96);
      @include mq() {
        height: 5rem;
      }
    }
  } //kv
} //page_top

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  5% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.page_top .kv {
  img {
    width: 100%;
  }
  .swiper-slide-active .zoom img {
    animation: zoom 4s ease-out 0s 1 normal both;
  }

  .swiper-container-fade .swiper-slide {
    // opacity: 0 !important;
    visibility: hidden !important;
    .slide-inner {
      opacity: 0; // slideの中のインナーにアニメーションを設定する
      transition: all .5s ease-in;
      position: relative;
      // left: 100%;
    }
    &.swiper-slide-active {
      opacity: 1 !important;
      visibility: visible !important;
      .slide-inner.is-anim {
        opacity: 1;
        transition: all 3s ease-in;
        left: 0;
      }
    }
  }
}

/*--------------------------------
top concept
--------------------------------*/
.top_concept {
  overflow: hidden;
  &.wrap {
    margin: 10rem auto 0;
    @include mq() {
      margin: 7rem auto 0;
    }
  }
  .cont_wrap {
    @include flex;
    align-items: center;
    margin-top: 9rem;
    @include mq() {
      display: block;
      margin-top: 6rem;
    }
  }
  .left, .right {
    width: 50%;
    @include mq() {
      width: 100%;
    }
  }
  .left {
    text-align: center;
  }
  .right {
    height: 50rem;
    padding-right: 3rem;
    @include mq('xmd-sm') {
      height: 43vw;
    }
    @include mq() {
      height: auto;
    }
    &.vertical_txt {
      line-height: 2.6;
      @include mq() {
        line-height: 1.8;
        writing-mode: horizontal-tb;
      }
      p {
        font-size: 1.6rem;
        margin-left: 3em;
        @include mq('xmd-sm') {
          font-size: 1.4vw;
        }
        @include mq() {
          font-size: 1.4rem;
          margin-left: 0;
        }
      }
    }
  }
  .catch {
    font-size: calcvwpc(36);
    @include mq() {
      font-size: 1.8rem;
    }
  }
  .btn_more {
    margin-top: 8rem;
    &.sp_none {
      @include mq() {
        display: none;
      }
    }
    &.pc_none {
      display: none;
      @include mq() {
        display: block;
        margin: 4rem auto 0;
      }
    }
  }
  .desc {
    p {
      @include mq() {
        margin-top: 3rem;
      }
    }
  } //desc
} //top_concept


/*--------------------------------
top product
--------------------------------*/
.page_top {
  .top_product {
    margin: 15rem auto 0;
    @include mq() {
      margin: 7rem auto 0;
    }
    @include mq() {
      #thumbnail01_sp {
        width: 80%;
        margin-top: 3rem;
        .swiper-slide {
          font-size: 0;
        }
      }
    }
  } //top_product
  .product_info {
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3rem 3rem #eee;
    margin-top: 6rem;
    padding: calcvwpc(40) calcvwpc(140) calcvwpc(50) calcvwpc(100);
    position: relative;
    &.wrap {
      @include mq() {
        box-shadow: none;
        margin-top: 3rem;
        padding: 0;
        width: 100%;
      }
    }
    .more_txt {
      position: absolute;
      bottom: 2rem;
      right: 0;
      line-height: 1;
      width: 13em;
      text-align: center;
      font-size: calcvwpc(22);
      @include mq('xxl-min') {
        font-size: 2.2rem;
      }
      p {
        margin-left: 4em;
      }
      .line_fade {
        margin-top: 1rem;
        width: 100%;
      }
      &:hover {
        .line_fade {
          transform: scaleX(1);
          transition: .6s .2s cubic-bezier(.79,.17,.15,.96);
        }
      }
    } //more_txt
  } //product_info
} //page_top

.page_top {
  .product_list {
    display: flex;
    opacity: 0;
    height: 0;
    transform: scaleY(0);
    transition: all .5s ease-in;
    @include mq() {
      position: relative;
    }
    &.active {
      opacity: 1;
      height: 100%;
      transform: scaleY(1);
      transition: all 1s ease-in;
    }
    .left {
      width: 30%;
      position: relative;
      @include mq() {
        width: 100%;
      }
      .txt_info {
        .ttl {
          font-size: calcvwpc(32);
          @include mq('xxl-min') {
            font-size: 2.8rem;
          }
          @include mq() {
            font-size: calcvw(32);
            display: inline-block;
            vertical-align: middle;
            margin-left: 1rem;
          }
        }
        .img_ttl_side {
          @include mq() {
            display: inline-block;
            vertical-align: middle;
            width: 10rem;
          }
        }
        .desc {
          font-size: calcvwpc(18);
          margin-top: calcvwpc(60);
          @include mq('xxl-min') {
            font-size: 1.6rem;
          }
          @include mq() {
            font-size: 1.4rem;
            margin-top: 5rem;
          }
        }
      } //txt_info
      .swiper-container {
        @include mq() {
          background: #fff;
          box-shadow: 0px 1rem 1rem #eee;
          margin: 0 10%;
        }
      }
      .swiper-slide {
        @include mq() {
          padding: 0 2rem 4rem;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        display: none;
        @include mq() {
          background: #fff;
          box-shadow: 0px 1rem 1rem #eee;
          border-left-color: transparent;
          border-right-color: transparent;
          border: none;
          color: transparent;
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          width: 5rem;
          height: 5rem;
          &::after {
            content: "";
            display: block;
            position: absolute;
            border: .2em solid transparent;
            color: #707070;
            width: 0;
            height: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      .swiper-button-prev {
        @include mq() {
          left: 0;
          &::after {
            border-right-color: #707070;
            left: 1rem;
          }
        }
      }
      .swiper-button-next {
        @include mq() {
          right: 0;
          &::after {
            border-left-color: #707070;
            right: 1rem;
          }
        }
      }
    } //left
    .btn_more {
      margin-top: calcvwpc(100);
    }
    .right {
      margin-left: 10%;
      width: 60%;
      .swiper-container {
        width: 100%;
        height: 46vw;
        max-height: 74rem;
        @media screen and (min-width: 1600px) and (max-width: 1900px) {
          max-height: 78rem;
        }
      }
      .swiper-wrapper {
        flex-wrap: wrap;
      }
      .swiper-slide {
        font-size: 0;
        display: inline-block;
        height: auto;
        // height: 14.3vw;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .txt {
        font-size: 1rem;
        position: absolute;
        top: -2rem;
      }
    } //right
    .slide_nav_wrap {
      width: auto;
      position: relative;
      bottom: 4rem;
      text-align: center;
      z-index: 10;
    }
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-pagination {
      display: inline-block;
      // position: static;
      vertical-align: middle;
    }
    .swiper-button-next,
    .swiper-button-prev {
      top: 95%;
      margin: 0;
      left: inherit;
      right: inherit;
      position: relative;
    }
    .swiper-button-prev:after,
    .swiper-container-rtl .swiper-button-next:after,
    .swiper-button-next:after {
      content: '';
    }
    .swiper-button-prev {
      color: #707070;
      border: .5em solid;
      border-color: transparent #707070 transparent transparent;
      width: 0;
      height: 0;
    }
    .swiper-button-next {
      color: #707070;
      border: .5em solid;
      border-color: transparent transparent transparent #707070;
      width: 0;
      height: 0;
    }
    .swiper-pagination {
      position: static;
    }
    .swiper-pagination-bullet {
      margin: 0 5px;
    }
    .swiper-pagination-bullet-active {
      background: $color-base;
    }
  } //product_list
}


/*三角*/
.triangle_wrap {
  display: inline-block;
  width: 0;
  height: 0;
  border: .5em solid;
  border-color: #707070 transparent transparent transparent;
  position: absolute;
  top: 1em;
  right: 1rem;
}

.cp_ipselect {
  display: block;
  width: 8em;
  height: 100%;
  position: relative;
  z-index: 10;
  margin-left: auto;
  padding: 2vw 0;
  @include mq('lg-md') {
    padding: 2rem 0;
  }
  @include mq() {
    display: none;
  }
}

.cp_sl_expand:checked {
  &+.cp_sl_closeLabel {
    &+.cp_sl_options {
      &+.cp_sl_expandLabel {
        display: none;
      }
    }
  }
}

.cp_sl_expand {
  width: 0;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  &::after {
    position: absolute;
    top: 1.2em;
    right: 0.5em;
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    pointer-events: none;
    border-top: 6px solid rgba(0, 0, 0, 0.3);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    transform: translate(-50%, -50%) rotate(0deg) scaleY(1.75);
    transition: all 250ms cubic-bezier(0.4,0.25,0.3,1);
    z-index: 2;
    opacity: 0.6;
  }
} //cp_sl_expand
.cp_sl_expand:hover::after {
  opacity: 1;
}
.cp_sl_expand:checked::after {
  transform: translate(-50%, -50%) rotate(180deg) scaleX(-1) scaleY(1.75);
}
.cp_sl_expand:checked {
  &+.cp_sl_closeLabel {
    &+.cp_sl_options {
      &+.cp_sl_expandLabel {
        display: none;
      }
    }
  }
}

.cp_sl_expandLabel {
  display: block;
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.cp_sl_close,
.cp_sl_input,
.cp_sl_closeLabel {
  display: none;
}

.cp_sl_closeLabel {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.cp_sl_items {
  width: 10em;
  position: absolute;
  top: 0;
  right: 0;
  @include mq('xxl-min') {
    width: 12em;
  }
}

.cp_sl_label {
  background-color: rgba(255,255,255,.8);
  color: $color-base;
  cursor: pointer;
  display: block;
  font-size: calcvwpc(28);
  height: 0;
  line-height: 40px;
  overflow: hidden;
  padding-right: 2.4em;
  transition: all .6s cubic-bezier(0.4,0.25,0.3,1);
  @include mq('xxl-min') {
    font-size: 2.6rem;
    padding-right: 1.8em;
  }
}

// .cp_sl_placeholder {
//   height: 40px;
//   vertical-align: middle;
//   position: absolute;
//   top: 0;
//   left: 0;
//   opacity: 0.6;
//   background-color: transparent;
// }
// .cp_sl_expand:checked + .cp_sl_closeLabel {
//   display: block;
// }
.cp_sl_expand + .cp_sl_closeLabel + .cp_sl_options {
  text-align: right;
}
.cp_sl_expand:checked + .cp_sl_closeLabel + .cp_sl_options .cp_sl_label {
  height: 40px;
}
.cp_sl_label::after {
  content: "";
  display: block;
  width: 72%;
  height: 1px;
  background: #707070;
  margin: -8px 0 0 .5em;;
  transform: scale(0);
  position: absolute;
  transition: all .3s cubic-bezier(0.4,0.25,0.3,1);
}
.cp_sl_expand:checked + .cp_sl_closeLabel + .cp_sl_options .cp_sl_label:hover::after {
  transform: scale(1);
}
.cp_sl_input:checked + .cp_sl_label {
  height: 40px;
}

/*--------------------------------
top series
--------------------------------*/
.top_series {
  overflow: hidden;
  margin-top: 16rem;
  @include mq {
    margin-top: 10rem;
  }
  .txt_area {
    display: block;
    &.wrap {
      width: 54%;
      max-width: 105rem;
      margin: 5rem auto;
      @include mq(lg-md) {
        width: 90%;
      }
      @include mq() {
        margin: 2rem auto 6rem;
        width: 76%;
      }
    }
    .inner_wrap {
      margin-top: 4rem;
      @include mq() {
        margin-top: 2rem;
        width: 100%;
      }
      &.vertical_txt {
        @include mq() {
          -ms-writing-mode: horizontal-tb;
          writing-mode: horizontal-tb;
        }
      }
    }
  } //txt_area
  .ttl {
    font-size: 2.8rem;
    margin-left: 1rem;
    @include mq() {
      font-size: 1.8rem;
      margin-left: 0;
      text-align: center;
      white-space: nowrap;
    }
  }
  .desc {
    font-size: 1.6rem;
    height: 38rem;
    line-height: 2.6;
    // margin: auto calcvwpc(190) auto calcvwpc(290);
    margin: auto calcvwpc(190);
    @include mq() {
      font-size: 1.4rem;
      height: auto;
      line-height: 2;
      margin: 2rem auto 0;
      text-align: left;
      width: 90%;
    }
  }
  .btn_more_wrap {
    margin-top: 2em;
    @include mq() {
      margin-top: 8rem;
      text-align: center;
    }
  }
  .series_bamboo {
    margin-top: 7rem;
    @include mq() {
      margin-top: 3rem;
    }
    .ttl {
      span:nth-of-type(2) {
        text-indent: 7em;
        @include mq() {
          text-indent: 0;
        }
      }
    }
  } //series_bamboo
  .series_tableware {
    margin-top: 20rem;
    @include mq() {
      margin-top: 0;
    }
    .txt_area {
      @media screen and (max-width: 768px) and (min-width: 601px) {
        width: 66%;
      }
    }
    .ttl {
      span:nth-of-type(2) {
        text-indent: 3em;
        @include mq() {
          text-indent: 0;
        }
      }
    }
  }
}

.page_top {
  .link_area {
    margin-top: 26rem;
    @include mq() {
      margin-top: 0;
    }
  }
}