@charset "UTF-8";
@import '../vars.scss';
.page_contact {
  padding-bottom: 28rem;
  @include mq() {
    padding-bottom: 20rem;
  }
  .lead {
    &.cont_wrap {
      @include mq() {
        padding: 5rem 0 0;
      }
    }
    .content p {
      margin-top: 0;
    }
  } //lead
  .text_cl {
    text-align: center;
    @include mq() {
      text-align: left;
    }
  }
  .tel_fax {
    align-items: center;
    margin-top: 6rem;
    text-align: center;
    @include flex;
    @include mq() {
      width: 94%;
      margin: 3rem auto 0;
    }
    .txt {
      @include mq() {
        border-bottom: 1px solid;
        display: inline-block;
        padding: 4px 1em;
        margin-bottom: 1rem;
      }
    }
    .num {
      font-size: 2.8rem;
      line-height: 1;
      white-space: nowrap;
      @include mq() {
        font-size: calcvw(30);
        letter-spacing: 0;
      }
    }
    .slash_mark {
      background: $color-base;
      width: 7.6rem;
      height: 1px;
      transform: rotate(-45deg);
      @include mq() {
        display: none;
      }
    }
  }
  .form_area {
    margin: 10rem auto 0;
    padding-right: 10rem;
    width: 88rem;
    @include mq() {
      margin: 4rem auto 0;
      padding-right: 0;
      width: 80%;
    }
    .note {
      margin: 0 0 6rem -10rem;
      text-align: center;
      @include mq() {
        margin: 0 0 2rem 0;
      }
    }
    .radio_btn {
      margin-left: 18rem;
      @include mq() {
        margin-left: 0;
        border: 1px solid;
        padding: 2rem;
      }
      .answer {
        display: none;
        @include mq() {
          display: block;
          margin: 1rem 0;
        }
      }
    }
    .contact_cont {
      width: 69rem;
      @include mq() {
        width: 86%;
        margin: 3rem auto 0;
      }
    }
    .list, .answer {
      display: inline-block;
      margin-top: 2.5rem;
      @include mq() {
        display: block;
        margin-top: .5rem;
      }
    }
    .list {
      margin-right: 1em;
      text-align: right;
      width: 16.4rem;
      white-space: nowrap;
      vertical-align: middle;
      @include mq() {
        text-align: left;
        width: auto;
      }
      &.txtop {
        vertical-align: top;
      }
    }
    .answer {
      border: 1px solid;
      width: 50rem;
      height: 5rem;
      vertical-align: middle;
      @include mq() {
        width: 100%;
        height: 4rem;
      }
      input {
        font-size: 1.4rem;
        padding: .5em 1em;
        width: 100%;
        height: 100%;
      }
      &.txtarea {
        height: 48rem;
        @include mq() {
          height: 26rem;
        }
      }
    } //answer
    .up_space {
      margin-top: 5rem;
      @include mq() {
        margin-top: 0;
      }
    }
    .mwform-radio-field:nth-of-type(2) {
      margin-left: 12rem;
      @include mq() {
        margin-left: 0;
      }
    }
  } //form_area
  .submit_area {
    margin: 13rem 0 0 9.6rem;
    text-align: center;
    @include mq() {
      margin: 6rem 0 0 0;
    }
  }
  .btn {
    border: 1px solid $color-base;
    border-radius: 1rem;
    color: $color-base;
    cursor: pointer;
    font-size: 2.8rem;
    width: 30rem;
    height: 8rem;
    @include mq() {
      font-size: 1.8rem;
      width: 64%;
      height: 6rem;
    }
  }
  .attention {
    margin-top: 6rem;
    @include mq() {
      width: 80%;
      margin:3rem auto 0;
    }
  }
  .list.sp_none,
  .answer.sp_none {
    @include mq() {
      display: none!important;
    }
  }
  textarea {
    font-size: 1.4rem;
    height: 100%;
    width: 100%;
    padding: .5em 1em;
  }
  .mw_wp_form .error {
    padding-left: 14em;
  }
} //page_contact

.mwform-radio-field {
  display: inline-block;
  position: relative;
  @include mq() {
    display: block;
  }
  label {
    display: inline-block;
    line-height: 1;
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      border: 1px solid;
      border-radius: 3em;
      width: 1.8rem;
      height: 1.8rem;
      vertical-align: middle;
    }
    .mwform-radio-field-text {
      vertical-align: middle;
    }
    .mwform-radio-field-text::after {
      content: "";
      display: none;
      background: #0085D0;
      border: 1px solid #0085D0;
      border-radius: 3em;
      width: 8px;
      height: 8px;
      vertical-align: middle;
      position: absolute;
      top: 5px;
      left: 5px;
    }
    input[type=radio]:checked {
      color: #0085D0;
      & + .mwform-radio-field-text::after {
        display: inline-block;
      }
    }
  }
}

.page_contact.contact_confirm {
  .form_area {
    .note {
      display: none;
    }
    .radio_btn {
      @include mq() {
        border: none;
        padding: 0 2rem;
      }
    }
    .answer {
      border: none;
      padding: .7rem;
      height: auto;
      &.txtarea {
        @include mq() {
          height: auto;
        }
      }
    }
    .btn_back {
      margin-right: 2rem;
      @include mq() {
        margin: 0 0 2rem 0;
      }
    }
  }
}

.page_contact.contact_thanks {
  .lead {
    .content {
      text-align: center;
    }
  }
  .submit_area {
    margin-left: 0;
    .btn {
      display: inline-block;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
    }
  }
}