@charset "UTF-8";
@import '../vars.scss';
/*--------
縦書きfadeIn
--------*/
.vertical_fade {
  transform: translate3d(80px,60px,0) skewY(15deg) skewX(10deg);
  transform-origin: left top;
  opacity: 0;
  transition: transform 2s cubic-bezier(.08,.92,.35,1),opacity 2s;
}

.vertical_fade.show {
  transform: translate3d(0,0,0) skewY(0deg) skewX(0deg);
  opacity: 1;
  transition: transform 2s cubic-bezier(.08,.92,.35,1),opacity 2s;
}

/*--------
ライン表示
--------*/
.line_fade {
  background: #707070;
  display: block;
  width: 100%;
  height: 1px;
  margin-top: 1.4rem;
  transform-origin: left;
  transform: scaleX(0);
  @include mq() {
    width: 50%;
  }
}

.line_fade.show {
  transform: scaleX(1);
  transition: .6s .2s cubic-bezier(.79,.17,.15,.96);
}

/*--------
ラウンド枠線
--------*/
.frame_fade span {
  background: transparent;
  border-radius: 1rem;
  display: inline-block;
  opacity: .3;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
}

.frame_fade span:first-of-type {
  border-top: 1px solid;
  transform-origin: left;
  transform: scaleX(0);
}

.frame_fade span:nth-of-type(2) {
  border-left: 1px solid;
  transform-origin: bottom;
  transform: scaleY(0);
}

.frame_fade span:nth-of-type(3) {
  border-bottom: 1px solid;
  transform-origin: right;
  transform: scaleX(0);
}

.frame_fade span:last-of-type {
  border-right: 1px solid;
  transform-origin: top;
  transform: scaleY(0);
}

.frame_fade.show span:nth-of-type(odd) {
  transform: scaleX(1);
  transition: .7s .42s cubic-bezier(.79,.17,.15,.96);
}

.frame_fade.show span:nth-of-type(even) {
  transform: scaleY(1);
  transition: .5s cubic-bezier(.79,.17,.15,.96);
}

/*--------
hover枠線
--------*/
.frame_hover span {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: block;
  background: #707070;
  transition: .3s ease;
}

.frame_hover span:first-of-type {
  transform-origin: left;
  transform: scaleX(0);
}

.frame_hover span:nth-of-type(2) {
  transform-origin: bottom;
  left: calc(100% - 4px);
  transform: scaleY(0);
  @include mq() {
    left: calc(100% - 2px)
  }
}

.frame_hover span:nth-of-type(3) {
  top: calc(100% - 4px);
  transform-origin: right;
  transform: scaleX(0);
  @include mq() {
    top: calc(100% - 2px)
  }
}

.frame_hover span:last-of-type {
  transform-origin: top;
  transform: scaleY(0);
}

.swiper-slide .frame_hover span:nth-of-type(odd) {
  width: 100%;
  height: 4px;
  @include mq() {
    height: 2px;
  }
}

.swiper-slide .frame_hover span:nth-of-type(even) {
  width: 4px;
  height: 100%;
  @include mq() {
    width: 2px;
  }
}

.swiper-slide:hover .frame_hover span:nth-of-type(odd) {
  transform: scaleX(1);
  transition: .4s cubic-bezier(.79,.17,.15,.96);
}

.swiper-slide:hover .frame_hover span:nth-of-type(even) {
  transform: scaleY(1);
  transition: .4s cubic-bezier(.79,.17,.15,.96);
}

/*--------
more button
--------*/
.btn_more {
  font-size: 1.6rem;
  position: relative;
  display: inline-block;
  padding: 2.6rem 10rem;
  transition: all 2s;
  text-align: center;
  @include mq() {
    font-size: 1.4rem;
    padding: 1rem 0;
    width: 15rem;
  }
  &:hover {
    background-color: #707070;
    border-radius: 1rem;
    color: #fff;
    transition: all 1s;
  }
  span {
    transition: all .3s;
    &:first-of-type {
      transform: scaleX(0);
    }
    &:nth-of-type(2) {
      transform: scaleY(0);
    }
    &:nth-of-type(3) {
      transform: scaleX(0);
    }
    &:nth-of-type(4) {
      transform: scaleY(0);
    }
  }
} //btn_more
.btn_more.variable {
  padding: calcvwpc(26) calcvwpc(100);
  @include mq('xxl-min') {
    padding: 2.6rem 10rem;
  }
  @include mq() {
    padding: 1rem 0;
  }
}

.vertical_txt .btn_more {
  padding: 10rem 2.6rem;
  @include mq() {
    padding: 1rem 0;
  }
}

/*--------
スライド画像表示
--------*/
.slide_img {
  position: relative;
}

.slide_img img {
  display: block;
  height: auto;
  width: 100%;
 }

.slide_img::before {
  background: #fff;
  content: "";
  display: block;
  position: absolute;
  right: 0;
  height: 100%;
  width: 100%;
  transition: width .5s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.slide_img.show::before {
  width: 0;
}

/*--------
テキストスライド表示
--------*/
.slide_display_txt {
  position: absolute;
  z-index: 10;
  .slide_txt_wrap {
    font-size: calcvwpc(28);
    line-height: 0;
    @include mq() {
      font-size: calcvw(32);
    }
    .slide_txt_box {
      display: inline-block;
      line-height: 2;
      position: relative;
      overflow: hidden;
      .txt {
        background: #fff;
        padding: 2rem 0;
        position: relative;
        z-index: 10;
        // transform-origin: top;
        // transform: scaleY(0);
        opacity: 0;
        white-space: nowrap;
      }
    }
  }
  .slide_txt_scroll {
    background: #333;
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    transform: scale(1);
    transform-origin: top;
    width: 100%;
    height: 100%;
    z-index: 20;
  }
  .slide_txt_wrap:first-of-type .slide_txt_scroll,
  .slide_txt_wrap:first-of-type .txt {
    // transition: .4s all cubic-bezier(0.75, 0.065, 0.675, 0.49);
  }

  .slide_txt_wrap:nth-of-type(2) .slide_txt_scroll,
  .slide_txt_wrap:nth-of-type(2) .txt {
    // transition: .7s all cubic-bezier(0.75, 0.065, 0.675, 0.49);
  }

  .slide_txt_scroll.show,
  .slide_txt_wrap .txt.show {
    // transform: scaleY(1);
  }
} //slide_display_txt
