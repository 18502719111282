@charset "UTF-8";
@import '../vars.scss';
body {
  padding-right: 6.4vw;
  @include mq('xxl-min') {
    padding-right: 9rem;
  }
  @include mq() {
    padding-right: 0;
  }
}

main {
  @include mq() {
    padding-top: 5rem;
  }
}

.flex_wrap {
  @include flex;
  @include mq() {
    @include flex($wrap: wrap);
  }
}

.link_area {
  width: 72%;
  max-width: 140rem;
  margin: 0 auto;
  @include mq('lg-md') {
    width: 90%;
  }
  @include mq() {
    width: 68%;
  }
}

.upper_part {
  display: table;
  width: 72%;
  max-width: 140rem;
  margin: 0 auto;
  padding: 10rem 0 0;
  @include mq('lg-md') {
    width: 90%;
  }
  @include mq() {
    font-size: calcvw(18);
    padding: 3rem 0 0;
    width: 68%;
  }
  * {
    display: inline-block;
    vertical-align: middle;
  }
  .left_txt {
    display: table-cell; text-align: left;
    width: 42%;
  }
  .empty {
    display: table-cell; text-align: right;
    width: 42%;
  }
  .line {
    background: $color-base;
    width: 60%;
    height: 1px;
    margin-left: 2em;
    @include mq() {
      width: 42%;
      margin-left: .5em;
    }
  }
  .company_name {
    display: table-cell; text-align: center;
    width: 15%;
    white-space: nowrap;
  }
}
