@charset "UTF-8";
@import '../vars.scss';
/*--------
fancy button
--------*/
.fancy_button {
  display: inline-block;
  letter-spacing: 0.03em;
  position: relative;
  width: 100%;
  @include mq() {
    margin-bottom: 3rem;
  }
  &::before {
    content: '';
    display: inline-block;
    height: 4rem;
    position: absolute;
    bottom: -5px;
    left: 3rem;
    right: 3rem;
    z-index: -1;
    border-radius: 30em;
    filter: blur(20px) brightness(0.95);
    transform-style: preserve-3d;
    transition: all 0.3s ease-out;
  }
  div {
    box-shadow: 0px 4rem 3rem #eee;
    border-radius: 1rem;
    width: 100%;
    height: 8vw;
    max-height: 15rem;
    padding: 3rem 2rem;
    position: relative;
    z-index: 2;
    will-change: transform, filter;
    transform-style: preserve-3d;
    transition: all 0.3s ease-out;
    @include flex_c($wrap: wrap);
    @include mq('lg-md') {
      height: 12rem;
    }
    @include mq() {
      box-shadow: 0px 1.4rem 3rem #eee;
      height: calcvw(110);
      min-height: 4rem;
      padding: 1rem 2rem;
    }
  }
  &:focus,
  &:active {
    color: $color-base;
  }
  &:hover {
    color: $color-base;
  }
  &:hover div {
    filter: brightness(1.05) contrast(1.0);
    transform: scale(0.95);
  }
  &:hover div::before {
  bottom: 0;
  filter: blur(10px) brightness(0.95);
  }
}

.link_area {
  li {
    width: 46%;
    @include mq() {
      width: 100%;
    }
    .ja {
      font-size: 2.8rem;
      @include mq() {
        font-size: calcvw(32);
      }
    }
    div p {
      text-align: center;
      width: 100%;
      line-height: 1;
    }
  }
}



