@charset "UTF-8";
@import '../vars.scss';
.page_company {
  padding-bottom: 30rem;
  @include mq() {
    padding-bottom: 9rem;
  }
  .page_kv {
    .list {
      text-indent: 2em;
      @include mq() {
        text-indent: 0;
      }
    }
  }
  .sec_ttl {
    font-size: 2.4rem;
    margin-bottom: 5rem;
    text-align: center;
    @include mq() {
      font-size: 2rem;
      margin-bottom: 3rem;
    }
  }
  .lead {
    .content {
      @media screen and (min-width: 1320px) {
        white-space: nowrap;
      }
    }
  }
  .compamy_info {
    margin-top: 15rem;
    &.cont_wrap {
      @include mq() {
        padding: 0;
      }
    }
    .desc {
      margin-top: 5rem;
      text-align: center;
      @include mq() {
        margin-top: 3rem;
        text-align: left;
      }
    }
    .table_definition {
      line-height: 1.8;
      width: 100%;
      max-width: 100rem;
      margin: 9rem auto 0;
      @include mq() {
        width: 68%;
        margin: 4rem auto 0;
      }
      dt, dd {
        display: inline-block;
        @include mq() {
          text-align: center;
        }
      }
    }
    dt {
      vertical-align: top;
      width: 22%;
      @include mq() {
        width: 100%;
        margin-top: 2rem;
        &:after {
          content: "";
          display: block;
          border-bottom: 1px solid;
          color: $color-base;
          width: 4.6em;
          margin: 1px auto 1rem;
        }
      }
    } //dt
    dd {
      width: 76%;
      &.multi_line.align_c {
        p {
          text-align: left;
        }
      }
      @include mq() {
        width: 100%;
        &.multi_line {
          text-align: left;
        }
        &.multi_line.align_c {
          text-align: center;
          p {
            display: inline-block;
          }
        }
      }
    } //dd
    a {
      text-decoration: underline;
    }
  } //compamy_info
  .map_area {
    margin-top: 10rem;
    @include mq() {
      margin-top: 6rem;
    }
    iframe {
      @include mq() {
        height: 60vw;
      }
    }
  }
  .main_products {
    margin-top: 8rem;
    @include mq() {
      margin-top: 2rem;
    }
    .inner {
      @include flex;
      margin-top: 8rem;
      @include mq() {
        flex-direction: column;
        margin: 0 auto;
        width: 58%;
      }
    }
    .item {
      width: 45%;
      @include mq() {
        width: 100%;
        margin-top: 1rem;
        &:not(:first-of-type) {
          margin-top: 8rem;
        }
      }
      img {
        width: 100%;
      }
    }
    .txt {
      text-align: center;
      font-size: 2rem;
      @include mq() {
        font-size: 1.6rem;
      }
      a {
        text-decoration: underline;
      }
    }
  }
  .system_division {
    .sec_ttl {
      @include mq() {
        font-size: 1.9rem;
      }
    }
    margin-top: 15rem;
    @include mq() {
      margin-top: 4rem;
    }
    .inner {
      width: 78%;
      max-width: 100rem;
      margin: 0 auto;
      @include mq() {
        width: 68%;
      }
    }
    .desc {
      width: 44rem;
      margin: 0 auto;
      @include mq() {
        width: auto;
      }
      &+img {
        margin-top: 5rem;
      }
    }
    a {
      text-decoration: underline;
    }
    .link {
      font-size: 1.4rem;
      text-align: center;
    }
  } //system_division
} //page_company