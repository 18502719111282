// 使用したもの
.align_c {
  text-align: center;
}

// ----------------------



.mb_10 {
  margin-bottom: 10px;
}
.mb_20 {
  margin-bottom: 20px;
}
.mb_30 {
  margin-bottom: 30px;
}
.mb_40 {
  margin-bottom: 40px;
}
.mb_50 {
  margin-bottom: 50px;
}
.mb_60 {
  margin-bottom: 60px;
}
.mb_70 {
  margin-bottom: 70px;
}
.mb_00 {
  margin-bottom: 0;
}
.mt_5 {
  margin-top: 5px;
}
.mt_10 {
  margin-top: 10px;
}
.mt_20 {
  margin-top: 20px;
}
.mt_30 {
  margin-top: 30px;
}
.mt_40 {
  margin-top: 40px;
}
.mt_50 {
  margin-top: 50px;
}
.mt_60 {
  margin-top: 60px;
}
.mt_70 {
  margin-top: 70px;
}
.mt_00 {
  margin-top: 00px;
}
.mr_10 {
  margin-right: 10px;
}
.mr_20 {
  margin-right: 20px;
}
.mr_30 {
  margin-right: 30px;
}
.mr_40 {
  margin-right: 40px;
}
.mr_50 {
  margin-right: 50px;
}
.mr_60 {
  margin-right: 60px;
}
.mr_70 {
  margin-right: 70px;
}
.mr_00 {
  margin-right: 00px;
}
.ml_10 {
  margin-left: 10px;
}
.ml_20 {
  margin-left: 20px;
}
.ml_30 {
  margin-left: 30px;
}
.ml_40 {
  margin-left: 40px;
}
.ml_50 {
  margin-left: 50px;
}
.ml_60 {
  margin-left: 60px;
}
.ml_70 {
  margin-left: 70px;
}
.ml_00 {
  margin-left: 00px;
}
.pb_10 {
  padding-bottom: 10px;
}
.pb_20 {
  padding-bottom: 20px;
}
.pb_30 {
  padding-bottom: 30px;
}
.pb_40 {
  padding-bottom: 40px;
}
.pb_50 {
  padding-bottom: 50px;
}
.pb_60 {
  padding-bottom: 60px;
}
.pb_70 {
  padding-bottom: 70px;
}
.pb_00 {
  padding-bottom: 0;
}
.pt_5 {
  padding-top: 5px;
}
.pt_10 {
  padding-top: 10px;
}
.pt_20 {
  padding-top: 20px;
}
.pt_30 {
  padding-top: 30px;
}
.pt_40 {
  padding-top: 40px;
}
.pt_50 {
  padding-top: 50px;
}
.pt_60 {
  padding-top: 60px;
}
.pt_70 {
  padding-top: 70px;
}
.pt_00 {
  padding-top: 00px;
}
.pr_5 {
  padding-right: 5px;
}
.pr_10 {
  padding-right: 10px;
}
.pr_20 {
  padding-right: 20px;
}
.pr_30 {
  padding-right: 30px;
}
.pr_40 {
  padding-right: 40px;
}
.pr_50 {
  padding-right: 50px;
}
.pr_60 {
  padding-right: 60px;
}
.pr_70 {
  padding-right: 70px;
}
.pr_00 {
  padding-right: 00px;
}
.pl_5 {
  padding-left: 5px;
}
.pl_10 {
  padding-left: 10px;
}
.pl_20 {
  padding-left: 20px;
}
.pl_30 {
  padding-left: 30px;
}
.pl_40 {
  padding-left: 40px;
}
.pl_50 {
  padding-left: 50px;
}
.pl_60 {
  padding-left: 60px;
}
.pl_70 {
  padding-left: 70px;
}
.pl_00 {
  padding-left: 00px;
}

.align_l {
  text-align: left;
}

.align_r {
  text-align: right;
}

.f_left {
  float: left;
}
.f_right {
  float: right;
}