@charset "UTF-8";
@import '../vars.scss';
footer {
  box-shadow: 0px -1.5rem 3rem #eee;
  font-size: 1.8rem;
  padding: 5rem 0 3rem;
  position: relative;
  text-align: center;
  @include mq() {
    padding: 5rem 0;
  }
  .inner {
    width: 50rem;
    margin: 0 auto;
    @include mq() {
      width: 100%;
    }
  }
  .footer_menu {
    @include flex();
    @include mq() {
      display: none;
    }
    li {
      position: relative;
    }
    li a,
    .menu_arrow {
      display: inline-block;
      position: relative;
    }
    li:not(:first-of-type) a::before,
    .menu_arrow::before {
      content: "";
      display: -webkit-inline-box;
      height: 1em;
      width: 1px;
      background: #707070;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -1.2em;
    }
    .menu_arrow {
      cursor: pointer;
      display: inline-block;
      margin-right: 5px;
      position: relative;
      &:after {
        @include arrow(#707070 #707070 transparent transparent);
        content:"";
        display: inline-block;
        position: absolute;
        width: 8px;
        height: 8px;
        top: 38%;
        right: -.8em;
        transform: rotate(135deg);
        transition: all .2s;
      }
    } //menu_arrow
    .menu_arrow.open {
      &:after {
        transform: rotate(-45deg);
        top: 48%;
      }
    }
    .lower {
      opacity: 0;
      display: none;
      font-size: 0.8em;
      margin-top: 1rem;
      position: absolute;
      top: 2em;
      text-align: left;
      white-space: nowrap;
      a {
        border-right: none;
        margin-top: 5px;
      }
      li a::before {
        content: none;
      }
    }
  } //footer_menu
  .logo {
    margin: 14rem 0 4rem;
    width: 9.4rem;
    @include mq() {
      margin: 0 0 3rem;
      width: calcvw(140);
    }
  }
  .ltd {
    @include mq() {
      font-size: calcvw(28);
    }
  }
  address {
    font-style: normal;
    margin-top: 3rem;
    @include mq() {
      font-size: calcvw(22);
      margin-top: 0;
    }
  }
  .tel_wrap {
    @include flex(wrap, center);
    @include mq() {
      flex-direction: column;
      font-size: calcvw(22);
      margin-top: 1.6rem;
    }
    span {
      padding-left: 1em;
      @include mq() {
        padding-left: 0;
      }
    }
  }
  .copy {
    font-size: 1.1rem;
    margin-top: 4rem;
    @include mq() {
      font-size: 1rem;
      margin-top: 4rem;
    }
  }
  .to_top {
    display: none;
    background: $color-base;
    width: 3rem;
    height: 3rem;
    position: absolute;
    bottom: 7rem;
    right: 3rem;
    @include mq() {
      display: inline-block;
    }
    .arrow_top{
      @include arrow(#fff #fff transparent transparent);
      position: absolute;
      width: 6px;
      height: 6px;
      top: 46%;
      left: 41%;
    }
  } //to_top
}


