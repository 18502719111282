@charset "UTF-8";
@import 'vars.scss';
/*--------------------------------------------------
	link
--------------------------------------------------*/
#tab-menu li:hover {
  cursor: pointer; }

.clrfix:after {
  clear: both;
  content: "";
  display: block;
}

.fixed {
  position: fixed;
  width: 100%;
  height: 100%; }

.sp,
.slide_img img.sp {
  display: none;
}

@include mq() {
  .pc,
  .slide_img img.pc {
    display: none;
  }
  .sp,
  .slide_img img.sp {
    display: block;
  }
}

.vertical_txt {
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  font-feature-settings: "palt" 0;
  display: inline-block;
  vertical-align: top;
  span {
    display: inline-block;
  }
  .vertical_num {
    text-combine-upright: all;
    -ms-text-combine-horizontal: digits 2;
     -webkit-text-combine: horizontal;
  }
}
