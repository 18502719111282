@charset "UTF-8";
@import '../vars.scss';
/*--------------------------------
title
--------------------------------*/
.page_ttl {
  line-height: 1.4;
  margin-top: 10rem;
  text-align: center;
  @include mq() {
    margin-top: calcvw(30);
  }
  .ja {
    font-size: 3rem;
    @include mq() {
      font-size: calcvw(48);
    }
  }
  .en {
    @include mq() {
      font-size: calcvw(18);
    }
  }
}

/*--------------------------------
inner
--------------------------------*/
.page {
  .cont_wrap {
    background: #fff;
    padding: 4rem;
    width: 68%;
    max-width: 130rem;
    margin: 0 auto;
    @include mq('lg-md') {
      width: 90%;
    }
    @include mq() {
      background: transparent;
      padding: 4rem 0;
      width: 100%;
    }
  }
  .cont_inner {
    width: 62%;
    min-width: 60rem;
    max-width: 80rem;
    margin: 0 auto;
    @include mq('md-sm') {
      width: 86%;
      min-width: inherit;
    }
    @include mq() {
      width: 68%;
      min-width: inherit;
    }
  }
}
/*--------------------------------
kv
--------------------------------*/
.page {
  .page_kv {
    position: relative;
    margin-top: 7rem;
    @include mq() {
      margin-top: 4rem;
    }
    img {
      width: 100%;
    }
    .lead {
      position: relative;
      top: -12rem;
      margin-bottom: -12rem;
      @include mq() {
        top: -5rem;
        margin-bottom: -5rem;
      }
    }
  } //page_kv
} //page

/*--------------------------------
content
--------------------------------*/
.page {
  .content {
    p, ul {
      margin-top: 3rem;
    }
  }
}

