@charset "UTF-8";
/*
1920px
50%  960px
40%  768px
30%  576px
20%  384px
10%  192px
5%  96px
4%  76.8px
3%  57.6px
2%  38.4px
1%  19.2px
*/

/* ====================================================
Color
==================================================== */
$color-base: #707070;
$color-pale: #eee;

/* ====================================================
Font
==================================================== */
// $font-base: "游明朝体", "YuMincho", "游明朝", "Yu Mincho", "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN W3", HiraMinProN-W3, "ヒラギノ明朝 ProN", "Hiragino Mincho ProN", "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif;
$font-base: 'Noto Serif JP', "游明朝体", "Yu Mincho", YuMincho, serif;
/* ====================================================
Media Quary
==================================================== */
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

$breakpoint-sm: 640px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 960px !default;
$breakpoints: (
  'sm': 'screen and (max-width: 640px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 1000px)',
  'xl': 'screen and (max-width: 1200px)',
  'xxl': 'screen and (max-width: 1400px)',
  'sm-min': 'screen and (min-width: 641px)',
  'md-min': 'screen and (min-width: 769px)',
  'lg-min': 'screen and (min-width: 1001px)',
  'xl-min': 'screen and (min-width: 1201px)',
  'xxl-min': 'screen and (min-width: 1401px)',
  'lg-md': 'screen and (max-width: 1200px) and (min-width: 769px)',
  'xmd-sm': 'screen and (max-width: 1100px) and (min-width: 769px)',
  'md-sm': 'screen and (max-width: 1000px) and (min-width: 769px)',
) !default;
// * ex) include mq() {
//  CONTENT
//}

// ----
// * ex) @include mixin_menu();
// ----
@mixin remove_letterspace {
  letter-spacing: -0.4em;
  > * {
    letter-spacing: normal;
  }
}

@mixin text_hidden {
  overflow: hidden;
  text-indent: 200%;
  white-space: nowrap;
}

@mixin flex($wrap: nowrap, $justify: space-between) {
  display: flex;
  justify-content: $justify;
  flex-wrap: $wrap;
}

@mixin flex_c($wrap: nowrap) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: $wrap;
}

@mixin flex-direction($direction) {
  @if $direction == column {
   -webkit-flex-direction: vertical;
   -moz-flex-direction: vertical;
   -ms-flex-direction: column;
   -webkit-flex-direction: column;
   flex-direction: column;
  }
  @else {
    -webkit-flex-direction: horizontal;
    -moz-flex-direction: horizontal;
    -ms-flex-direction: row;
    -webkit-flex-direction: row;
    flex-direction: row;
  }
}

@mixin arrow($colors...) {
  border: 1px solid;
  border-color: $colors;
  display: inline-block;
  transform: rotate(-45deg);
}

// ----
// * ex) width: calcvw(200)
// ----
$sp-design-width: 750; // SPデザインの幅を設定
@function calcvw($num, $width: $sp-design-width) {
  @return (1vw * $num / $width * 100);
}

$pc-design-width: 1920; // pcデザインの幅を設定
@function calcvwpc($num, $width: $pc-design-width) {
  @return (1vw * $num / $width * 100);
}

// ----
// * ex) @extend %extend;
// ----
%abs_c {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

%abs_c_horizon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

%fixed {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
}



