@charset "UTF-8";
@import '../vars.scss';
.fade_group div,
.fade_group2 div {
  content: '';
  position: fixed;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100%;
  pointer-events: none;
}
.fade_group {
  div {
    z-index: 9999;
    transition: all .3s;
    &:nth-of-type(1) {
      background: #e8e8e8;
    }
    &:nth-of-type(2) {
      background: #e8e8e8;
    }
    &:nth-of-type(3) {
      background: rgba(255,255,255,.8);
      transition-delay: .1s;
    }
    &:nth-of-type(4) {
      background: rgba(255,255,255,.7);
      transition-delay: .2s;
    }
    &:nth-of-type(2) {
      background: #e8e8e8;
    }
  }
}

.fade_group2 {
  div {
    z-index: 9998;
    transition: all .5s;
    &:nth-of-type(1) {
      background: rgba(255,255,255,.8);
      transition-delay: .3s;
    }
    &:nth-of-type(2) {
      background: rgba(255,255,255,.8);
      transition-delay: .4s;
    }
  }
}

body.page_load {
  .fade_group div {
    left: 0vw;
  }
  .fade_group2 div {
    left: 100vw;
  }
}

body.page_load.leave {
  .fade_group div {
    background: #eee;
  }
  .fade_group2 div {
    background: #eee;
  }
}


