@charset "UTF-8";
@import '../vars.scss';
/*--------------------------------
concept
--------------------------------*/
.page_concept {
  overflow: hidden;
  .page_kv {
    position: relative;
    .slide_display_txt {
      top: -4em;
      right: 30%;
      @include mq() {
        right: 18%;
      }
      .left_txt {
        margin-top: 8em;
      }
    }
  } //page_kv
  .lead {
    .content {
      font-size: 1.6rem;
      text-align: center;
      @include mq() {
        text-align: left;
      }
    }
    .ttl {
      font-size: 2.4rem;
      margin: 6rem 0 3rem;
      @include mq() {
        font-size: 4.8vw;
        margin: 0 0 3rem;
      }
    }
  }
  .justify_cont {
    display: flex;
    align-items: center;
    @include mq() {
      flex-direction: column-reverse;
    }
    .img_wrap {
      width: calc(100% - 39rem;);
      height: 30vw;
      position: relative;
      @include mq() {
        width: 100%;
        height: calcvw(550);
      }
      .slide_img {
        height: 100%;
      }
    } //img_wrap
    .txt_area {
      width: 39rem;
      @include mq() {
        width: 68%;
      }
    }
    &.al_right {
      margin-left: 14%;
      @include mq() {
        margin-left: 0;
      }
      .img_wrap {
        margin-left: 4%;
        @include mq() {
          margin-left: 0;
        }
        img {
          margin-left: auto;
        }
      } //img_wrap
    } //al_right
    &.al_left {
      margin-right: 14%;
      @include mq() {
        margin-right: 0;
      }
      .img_wrap {
        margin-right: 4%;
        @include mq() {
          margin-right: 0;
        }
        img {
          margin-right: auto;
        }
      } //img_wrap
    } //al_right
    .sec_ttl {
      font-size: 2.4rem;
      @include mq() {
        font-size: calcvw(34);
      }
    }
  } //justify_cont
  .production {
    margin-top: 16rem;
    @include mq() {
      margin-top: 8rem;
      .vertical_txt {
        writing-mode: horizontal-tb;
      }
    }
    position: relative;
    .justify_cont {
      white-space: nowrap;
      @include mq() {
        white-space: normal;
      }
    }
    .txt_area {
      padding: 3rem 0;
      @include mq() {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
      }
    }
    .vertical_txt.desc {
      font-size: 1.6rem;
      line-height: 2.6;
      width: 50%;
      height: 39rem;
      white-space: normal;
      @include mq() {
        font-size: 1.4rem;
        line-height: 1.8;
        width: 100%;
        height: auto;
        margin-top: 3rem;
      }
    }
    .sec_ttl {
      margin-left: 20%;
      @include mq() {
        margin-left: 0;
      }
      span:last-of-type {
        text-indent: 4em;
        @include mq() {
          text-indent: 0;
        }
      }
    }
  } //production
  .lineup {
    margin-top: 20rem;
    @include mq() {
      margin-top: 8rem;
    }
    .slide_img {
      height: 15vw;
      @include mq() {
        height: calcvw(290);
      }
    }
    .note {
      width: 50rem;
      margin: 12rem auto 0;
      @include mq() {
        width: 68%;
        margin: 3rem auto 0;
      }
      .ttl {
        font-size: 2.4rem;
        margin: 10rem 0 3rem;
        @include mq() {
          font-size: calcvw(36);
          margin: 0 0 3rem;
        }
      }
    }
    .box_lineup {
      box-shadow: 0px -3rem 4rem #eee;
      margin-top: 24rem;
      padding: 16rem 0 24rem;
      @include mq() {
        box-shadow: none;
        margin-top: 10rem;
        padding: 0 0 6rem;
      }
      .sec_ttl {
        margin-top: 3rem;
      }
      .slide_display_txt {
        .txt {
          @include mq() {
            padding: 1em 0;
          }
        }
      }
      .bamboo {
        @include mq() {
          flex-direction: column;
        }
        .slide_display_txt {
          top: -2em;
          right: 16%;
          @include mq() {
            top: 1em;
          }
          .left_txt {
            margin-top: 8em;
          }
        }
      }
      .tableware {
        margin-top: 12rem;
        @include mq() {
          margin-top: 6rem;
        }
        .slide_display_txt {
          top: -2em;
          right: 16%;
          @include mq() {
            top: 2em;
          }
          .left_txt {
            margin-top: 3em;
          }
        }
      }
    } //box_lineup
    .product {
      font-size: 3.2rem
    }
    .desc {
      margin: 3rem 0;
    }
  } //lineup
  .link_area {
    margin-bottom: 12rem;
    @include mq() {
      margin-bottom: 6rem;
    }
  }
}

